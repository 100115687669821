import { API } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api";
import {
  ListReadsQuery,
  CreateReadMutation,
} from "../dcpdashboardbackendGraphql";
import { listReads } from "../graphql/dcpdashboardbackend/queries";
import { createRead } from "../graphql/dcpdashboardbackend/mutations";

export const getListRead = async (notificationIds: string[]) => {
  const result = (await API.graphql({
    query: listReads,
    variables: {
      filter: {
        or: notificationIds.map((id) => ({ notificationId: { eq: id } })),
      },
    },
  })) as GraphQLResult<ListReadsQuery>;

  const read = result.data.listReads.items.reduce((o, data) => {
    o[data.notificationId] = { id: data.id, read: data.read };
    return o;
  }, {}) as { [notificationId: string]: { id: string; read: boolean } };

  return read;
};

export const createReadRecord = (notificationId: string) =>
  API.graphql({
    query: createRead,
    variables: {
      input: {
        notificationId,
        read: true,
      },
    },
  }) as Promise<GraphQLResult<CreateReadMutation>>;
