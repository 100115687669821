import { useState, useEffect, useCallback } from "react";
import { DocumentNode, print } from "graphql";
import deepEqual from "deep-equal";
import { callContentfulApi } from "../utils/contentful";
import { Query } from "../contenfulGraphql";
import { getListRead } from "../utils/notificationRead";
import { useDeepMemo } from "./useDeepMemo";

type Variables = { [k: string]: unknown };

export const useNotification = (
  documentNode: DocumentNode,
  variables?: Variables
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<{
    notification: Query;
    read: { [notificationId: string]: { id: string; read: boolean } };
  }>();
  const [error, setError] = useState<Error>();
  const memo = useDeepMemo<Variables>(variables, (prev, current) =>
    deepEqual(prev, current)
  );

  const addRead = useCallback((newRead) => {
    setData(({ notification, read }) => ({
      notification,
      read: Object.assign(read, newRead),
    }));
  }, []);

  useEffect(() => {
    const load = async (): Promise<void> => {
      setIsLoading(true);
      try {
        const response = await callContentfulApi({
          body: {
            query: print(documentNode),
            variables,
          },
        });

        let readResult = null;
        if ((response.data as Query).notificationCollection.items.length > 0) {
          const notificationIds = (
            response.data as Query
          ).notificationCollection.items.map((item) => item.sys.id);
          readResult = await getListRead(notificationIds);
        }
        setData({ notification: response.data, read: readResult });
      } catch (e) {
        console.error(e);
        setError(e);
      } finally {
        setIsLoading(false);
      }
    };

    load();
  }, [memo]);

  return { isLoading, error, data, addRead };
};
