import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      "Welcome to React": "Welcome to React and react-i18next",
    },
  },
  ja: {
    translation: {
      'We couldn\'t find any matches for "{{query}}". Try checking for typos or using complete words.':
        '"{{query}}"に該当する連絡先は見つかりませんでした。誤字脱字の確認や完全一致する単語を使ってみてください。',
      " in {{productVariants}} variants":
        "{{productVariants}} 種類のバリエーションがあり",
      " in stock": "個の在庫あり",
      ".": "。",
      "{{charactersLeft200}} characters left": "残り{{charactersLeft200}}文字",
      "{{chatMessageCreateAt}} ago": "{{chatMessageCreateAt}}前",
      "{{commonConnctions}} connections in common":
        "共通のつながりが{{commonConnctions}}あります",
      "{{postCreateAt}} ago": "{{postCreateAt}}前",
      "{{projectActivityCreateAt}} ago": "{{projectActivityCreateAt}}前",
      "{{reviewsCount}} reviews in total":
        "{{reviewsCount}}件のレビューがあります",
      "$100 / month": "$100 / 月",
      "$250 / month": "$250 / 月",
      "$500 / month": "$500 / 月",
      "1 - {{emails}} of {{emails}}": "1 - {{emails}} / {{emails}} 件",
      "12% more then last month": "先月より 12% 増",
      "30% less then last month": "先月より 30% 減",
      "50% Set Up Complete": "50% 設定完了",
      "6 contacts in common": "共通の6つの連絡先",
      "Accepts Marketing": "営業活動中",
      "Add an item": "アイテムを追加する",
      "Add Card": "カードを追加する",
      "Add Column": "列を追加する",
      "Add Customer": "カスタマーの追加",
      "Add Event": "予定の追加",
      "Add Item": "アイテムの追加",
      "Add money": "入金する",
      "Add school or college": "学校または大学を追加する",
      "Add to card": "カードに追加する",
      "Address 1": "番地・他",
      "Address 2": "建物名",
      "Adjust font for small devices": "小さなデバイス用にフォントを調整",
      "ads left": "広告",
      "All day": "終日",
      "Analytics dashboard": "分析 ダッシュボード",
      "Apply for a role": "応募する",
      "Archive thread": "スレッドをアーカイブ",
      "Attach file": "ファイルを添付する",
      "Attach image": "写真を添付する",
      "Attach photo": "写真を添付する",
      "Author note: Please remember to include your soft skills.":
        "注意：コミュニケーションスキルも忘れずに記載してください。",
      "Available currency": "利用可能な通貨",
      "Available to hire": "アサインの可否",
      "Basic Plan": "ベーシックプラン",
      "Billing Cycle": "請求サイクル",
      "Block contact": "連絡をブロック",
      "Bounce rate": "直帰率",
      "by ": "担当者 ",
      "Cancel your plan": "プランのキャンセルはこちら",
      "Card archived!": "カードアーカイブ完了",
      "Card Expiry Date is required": "カード有効期限は必須項目です",
      "Card Expiry Date": "カード有効期限",
      "Card Number is required": "カード番号は必須項目です",
      "Card Number": "カード番号",
      "Card updated!": "カード更新完了",
      "Cart add": "カートに商品を追加",
      "Cart remove": "カートから商品を削除",
      "Case Studies": "事例",
      "Case Study": "事例",
      "Change Cover": "画像を変更",
      "Change Password": "パスワードの変更",
      "Change text direction": "テキスト向きの変更",
      "Chat App": "チャットアプリケーション",
      "Check item added!": "アイテム追加完了",
      "Check item deleted!": "アイテム削除完了",
      "Check item updated!": "アイテム更新完了",
      "Checklist added!": "チェックリスト追加完了",
      "Checklist deleted!": "チェックリスト削除完了",
      "Checklist updated!": "チェックリスト更新完了",
      "Close Account": "アカウントをクローズする",
      "Column cleared!": "列クリア完了",
      "Column deleted!": "列削除完了",
      "Column updated!": "列更新完了",
      "Comment added!": "コメント追加完了",
      "Company Name is required": "企業名は必須項目です",
      "Company Name": "企業名",
      "Compose a message": "メッセージを作成",
      "Contact Details": "連絡先",
      "Contact Form": "お問い合わせフォーム",
      "Contact Support": "お問い合わせ先",
      "Contacts allow you to manage your company contracts":
        "連絡先では、会社の契約を管理することができます",
      "Contest holder": "コンテスト開催者",
      "Conversion Rate": "コンバージョン率",
      "Cost Breakdown": "コストの内訳",
      "Create a new product": "新しい商品の作成",
      "Create Invoice": "請求書を作成する",
      "Create Product": "商品を作成する",
      "Create Wizard & Process": "ウィザードとプロセスの作成",
      "Creation date (newest first)": "作成日付順（降順）",
      "Creation date (oldest first)": "作成日付順（昇順）",
      "Credit Card": "クレジットカード",
      "Customer Edit": "カスタマー情報の編集",
      "Customer List": "カスタマーリスト",
      "Customer updated!": "アップデート完了",
      "Dashboard: ": "ダッシュボード： ",
      "Data capacity limit 10GB": "データ容量制限 10GB",
      "Data capacity limit 5GB": "データ容量制限 5GB",
      "Data Management": "データ管理",
      "Deadline in {{projectEnddate}}": "{{projectEnddate}}後に締め切り",
      "Delete Account": "アカウントを削除する",
      "Delete thread": "スレッドを削除する",
      "Disabling this will automatically send the user a verification email":
        "無効にすると、ユーザーに確認メールが自動的に送信されます",
      "Discounted Prices": "割引価格での提供",
      "Do you need help?": "困ったことはありませんか？",
      "Download Free PDF": "無料のPDFをダウンロードする",
      "Download our Free PDF and learn how to get more job leads":
        "無料のPDFをダウンロードして、より多くの仕事を獲得する方法を学びましょう",
      "Download PDF": "PDFをダウンロードする",
      "Drop file": "ファイルをドラッグ&ドロップ",
      "Edit Event": "予定を編集する",
      "Email address": "Eメールアドレス",
      "Email Address": "Eメールアドレス",
      "Email alerts": "Eメールでのお知らせ",
      "Email is required": "必須項目です",
      "Email not verified": "Eメールアドレス未検証",
      "Email verified": "Eメールアドレス検証済み",
      "Email Verified": "Eメールアドレス検証済み",
      "End date must be later than start date":
        "終了日は開始日より後でなければいけません",
      "End Date": "終了日",
      "End date": "終了日時",
      "Enter a keyword": "検索キーワードを入力する",
      "Export Data": "データをエクスポートする",
      "Find your dream job": "理想の仕事を見つけよう",
      "Fixed width on some screens": "一部の画面で幅を固定",
      "Freelancer Subscription (12/05/2019 - 11/06/2019)":
        "Freelancer Subscription（2019/12/05 - 2019/11/06）",
      "Full Name": "お名前",
      "Full name": "氏名",
      "Full Time": "正社員",
      "Get products": "製品情報取得",
      "Go to Application": "アプリケーションへ移動する",
      "Go to chat": "チャットへ移動する",
      "Good Morning,": "こんにちは、",
      "Gross Income": "総売上",
      "Have questions about our Service? Fill out the form and the team will be in touch shortly.":
        "サービスについてのお問い合わせがありましたら、下記フォームに必要事項を入力の上、送信してください。担当チームからすぐにご連絡いたします。",
      "Help Center": "ヘルプセンター",
      "Here's what you planned": "あなたの予定",
      "Here's what your connections posted":
        "つながりネットワークで投稿された内容です",
      "Here's what's happening with your projects today":
        "本日のプロジェクト状況はこちら",
      "https://www2.deloitte.com/global/en.html":
        "https://www2.deloitte.com/jp/ja/legal/legal.html",
      "I want to join affiliate": "アフィリエイトに参加したい方",
      "I'm a freelancer": "フリーランスの方",
      "I'm looking for freelancer or contractors to take care of my project":
        "プロジェクトを担当してくれるフリーランサーやコントラクターを探す",
      "I'm looking for teammates to join in a personal project":
        "個人的なプロジェクトに参加してくれるチームメイトを探す",
      "I’m a project owner": "プロジェクトオーナーの方",
      "If you have a sale price this will be shown as old price":
        "販売価格がある場合は、旧価格として表示されます",
      "In network": "ネットワーク内",
      "In Stock": "在庫あり",
      "Increase border radius": "境界線の半径を拡大",
      "Incremental Sales": "売上の増加分",
      "Invalid format": "無効な形式です",
      "invites left": "招待",
      "Invoice Details": "請求書詳細",
      "Invoice List": "請求書リスト",
      "Invoices/CheckoutBilling": "請求/支払い情報",
      "Keywords by country": "国別のアクセス数",
      "Last month": "先月",
      "Last update (newest first)": "最終更新日順（降順）",
      "Last update (newest)": "最終更新日順（降順）",
      "Last update (oldest first)": "最終更新日順（昇順）",
      "Last update (oldest)": "最終更新日順（昇順）",
      "Last Update": "最終更新日",
      "Last week": "先週",
      "last year": "前年",
      "Latest Transactions": "最新の取引",
      "Leave a message": "メッセージを返信する",
      "Live Preview": "ライブプレビュー",
      "Login as Customer": "カスタマーとしてログインする",
      "Make Template": "テンプレートを作成する",
      "Manage members": "メンバーを管理する",
      "Manage your plan": "プランを管理する",
      "Mark all as read": "すべて既読にする",
      "Mark Paid": "支払う",
      "Mark Unpaid": "支払わない",
      "Means that anyone viewing your profile will be able to see your contacts details":
        "この設定を切り替えると詳細な連絡先が他の閲覧者に公開されます",
      "Message is required": "必須項目です",
      "More options": "その他のオプション",
      "Most popular": "人気順",
      "Most recent": "新着順",
      "Most Visited Pages": "最もアクセスの多いページ",
      "Must be a valid email": "無効な形式です",
      "Must be at least 3 characters": "3文字以上入力してください",
      "Mute notifications": "通知をミュート",
      "My Contacts": "マイコンタクト",
      "Name is required": "必須項目です",
      "Name on Card is required": "必須項目です",
      "Name on Card": "カード名義",
      "Need help figuring things out?": "何か困ったことはありませんか？",
      "New Customers": "新しいカスタマー",
      "New Event": "新しい予定",
      "New Invoice": "新しい請求書",
      "New Message": "新しいメッセージ",
      "New Order": "新しい注文",
      "New Product": "新しい商品",
      "New Project": "新しいプロジェクト",
      "New Transaction": "新しい取引",
      "Newest first": "日付順（降順）",
      "Next email": "次のメール",
      "Next page": "次のページ",
      "No data capacity limit": "データ容量の制限なし",
      "No results": "結果はなし",
      "North America": "北アメリカ",
      "Nothing Found": "見つかりませんでした",
      "Notification Details": "通知詳細",
      "Oldest first": "日付順（昇順）",
      "On sale": "発表順",
      "Order confirmation": "受注確認",
      "Order Details": "注文詳細",
      "Order info": "注文情報",
      "Order items": "注文商品",
      "Order List": "注文リスト",
      "Out of Stock": "在庫切れ",
      "Overall Reviews": "総合レビュー",
      "Page Name": "ページ名",
      "Part Time": "パートタイム",
      "Password Confirmation": "パスワードの確認",
      "Payment information (Credit Card Only)":
        "お支払い情報（クレジットカードのみ）",
      "Payment Method": "支払い方法",
      "Payment received": "入金",
      "Payment sent": "振込",
      "Phone calls": "電話をかける",
      "Phone number": "電話番号",
      "Phone Number": "電話番号",
      "Please select one option": "一つ選択してください",
      "Polyester and Spandex": "ポリエステル,スパンデックス",
      "Premium Plan": "プレミアムプラン",
      "Preview PDF": "PDFをプレビューする",
      "Previous email": "前のメール",
      "Previous page": "前のページ",
      "Price high": "料金順（降順）",
      "Price includes taxes": "税込価格",
      "Price low": "料金順（昇順）",
      "Product Code": "商品コード",
      "Product Create": "商品作成",
      "Product created!": "商品が作成されました",
      "Product is taxable": "課税対象",
      "Product List": "商品リスト",
      "Product Name": "商品名",
      "Product Sku": "商品型番",
      "Profile Progress": "プロフィールの進捗状況",
      "Profile updated!": "プロフィールが更新されました",
      "Profitable Products": "収益性の高い商品",
      "Project Browse": "プロジェクト ブラウズ",
      "Project Create": "プロジェクト作成",
      "Project Details": "プロジェクト詳細",
      "Project members": "プロジェクトメンバー",
      "Project Name": "プロジェクト名",
      "Promotion Code": "プロモーションコード",
      "proposals left": "提案",
      "Public Profile": "プロファイルの公開",
      "Push notifications": "プッシュ通知",
      "Refresh rate is 24h": "更新間隔：24時間",
      "Remove All": "全て削除する",
      "Remove Picture": "写真を削除する",
      "Remove this customer’s chart if he requested that, if not please be aware that what has been deleted can never brought back":
        "お客様がデータの削除を希望した場合は、データを削除してください。削除されたデータは、復元できませんのでご注意ください。",
      "Reply all": "全員に返信",
      "Request sent!": "リクエストが送信されました",
      "Resend Due Invoices": "請求書を再送する",
      "Resend Invoice": "請求書を再送する",
      "Resend last invoice": "前回の請求書を再送",
      "Reset & Send Password": "パスワードリセット & 仮パスワード送信",
      "Responsive font sizes": "レスポンシブフォントサイズ",
      "Rounded Corners": "角の丸さ",
      "Sale price": "販売価格",
      "Sales by Continent": "大陸別の売上高",
      "Sales Revenue": "売上高の推移",
      "Save Changes": "変更を保存する",
      "Save Settings": "設定を保存する",
      "Search connections": "つながりを探す",
      "Search contacts": "連絡先を検索する",
      "Search customers": "カスタマーを検索する",
      "Search email": "メールを検索する",
      "Search invoices by customer": "カスタマー別に請求書を検索する",
      "Search Jobs": "仕事を探す",
      "Search message": "メッセージを検索する",
      "Search notifications": "通知を検索する",
      "Search products": "商品を検索する",
      "Search...": "検索...",
      "Security Code is required": "必須項目です",
      "Security Code": "セキュリティコード",
      "See all activity": "すべてのアクティビティを確認する",
      "See all visits": "すべてのアクセスを確認する",
      "See All": "すべてを確認する",
      "See the latest opportunities": "最新の募集要項を確認する",
      "Select all": "すべてを選択する",
      "Select file": "ファイルを選択する",
      "Select plan": "プラン選択する",
      "Select profession": "職業を選択する",
      "Send email": "Eメールを送信する",
      "Send Message": "メッセージを送信する",
      "Send password reset": "パスワードをリセットする",
      "Send verification": "検証コードを送信する",
      "Service Marketplace": "サービスマーケットプレイス",
      "Service Name": "サービス名",
      "Short Note": "ショートノート",
      "Short voice phone updating you": "短時間の音声電話によるお知らせ",
      "Showing {{projectsLength}} projects":
        "{{projectsLength}} 件のプロジェクトがあります",
      "Social Feed": "ソーシャルフィード",
      "Social Media Sources": "ソーシャルメディアからのアクセス",
      "Social Media": "ソーシャルメディア",
      "Social Profile": "ソーシャルプロファイル",
      "Something went wrong!": "何かがうまくいきませんでした",
      "Sort by": "並び順",
      "Sort By": "並び順",
      "South America": "南アメリカ",
      "Standard Plan": "スタンダードプラン",
      "Start Date": "開始日",
      "Start date": "開始日時",
      "State/Region": "県／地域",
      "Submit Success": "送信完了",
      "Subscribe failed": "申し込み失敗",
      "Subscribe Form": "お申し込みフォーム",
      "Subscribe success": "申し込み完了",
      "Subscribed!": "ウィッチ追加完了",
      "Text message": "テキストメッセージ",
      "The project requires an introduction":
        "このプロジェクトは、自己紹介が必要です",
      "The refunds don't work once you have the plan, but you can always ":
        "申し込んだプランは、返金しません。しかし、プランの解約はいつでも可能です。",
      "There are no news": "ニュースはありません",
      "There are no notifications": "通知はありません",
      "This is the service subscribe form. Please enter the required information and press the subscribe button.":
        "サービス申し込みフォームです。下記フォームに必要事項を入力のうえ、申し込みしてください。",
      "This will give the user discounted prices for all products":
        "有効にすると、ユーザーはすべての製品を割引価格で購入することができます",
      "thorough your machine": "してファイルを選択する",
      "Title is required": "タイトルは必須項目です",
      "To: ": "宛先: ",
      "To:": "宛先: ",
      "Toggling this will let your teammates know that you are available for acquiring new projects":
        "この設定を切り替えるとチームメイトにあなたが新しいプロジェクトに参加できることを知らせることができます",
      "Total Amount": "合計金額",
      "Total balance": "残高",
      "Total orders (highest)": "注文合計順（降順）",
      "Total orders (lowest)": "注文合計順（昇順）",
      "Total Transactions": "取引総額",
      "Traffic Sources": "トラフィックソース",
      "Try different search terms": "異なる検索条件を試してください",
      "Unique page visits": "ユニークユーザー数",
      "United Kingdom": "イギリス",
      "United States": "アメリカ",
      "Unpaid/Due": "未払い/期限切れ",
      "Unsubscribe failed": "解約失敗",
      "Unsubscribe from {{serviceTitle}} ?":
        "{{serviceTitle}} から解約しますか？",
      "Unsubscribe success": "解約完了",
      "Unsubscribe this service. If you unsubscribe from the service, the service will be disabled and the registered data will be deleted.":
        "サービスを解約します。サービスを解約すると、サービスの利用ができなくなり、登録済みデータが削除されます。",
      "Unsubscribed!": "ウォッチ解除完了",
      "Up/Cross-Selling": "アップ／クロスセリング",
      "Update Customer": "カスタマー情報を更新する",
      "Updated {{projectUpdatedAt}} ago": "{{projectUpdatedAt}}前に更新",
      "Upgrade Plan": "プランのアップグレード",
      "Upload Images": "画像のアップロード",
      "US Dollars": "米ドル",
      "Variety of styles": "様々な素材",
      "View all announcements": "すべてのアナウンスを表示",
      "View product": "商品閲覧",
      "View project": "プロジェクトを見る",
      "vs.": "対 ",
      "We will use this email to contact you":
        "このEメールアドレスはお客様へのご連絡に使用させていただきます",
      "Web Developer": "Web開発",
      "Weekly earnings": "週間収益",
      "What excites you about this project?":
        "今回のプロジェクトで、あなたがワクワクすることは何ですか？",
      "What's on your mind, {{userName}}": "{{userName}}が気になっていること",
      "Widget25 Source by channel": "Widget25 チャンネル別アクセス数",
      "Widget25 source by Social Media platforms":
        "Widget25 ソーシャルメディアプラットフォーム別アクセス数",
      "Withdraw funds": "出金する",
      "Withdraw money": "出金する",
      "Write a comment...": "コメントを追加",
      "Write down a short note with your application regarding why you think you'd be a good fit for this position.":
        "この役割に適していると思う理由についてショートノートを書いてください。",
      "Write something": "説明を追加",
      "XRP Ripple": "リップル（XRP）",
      "You are all done!": "プロジェクトが作成されました",
      "You will receive emails in your business email address":
        "仕事用のEメールアドレスにメールが届きます",
      "Your plan": "プラン",
      "Your private wallet": "プライベートウォレット",
      About: "プロフィール",
      Account: "アカウント",
      Actions: "アクション",
      Active: "アクティブ",
      Activity: "アクティビティ",
      Add: "追加",
      Africa: "アフリカ",
      Agenda: "予定表",
      All: "すべて",
      Amount: "金額",
      Analytics: "分析",
      Announcement: "アナウンス",
      Applicants: "応募者",
      Apps: "アプリケーション",
      Apr: "4月",
      Archive: "アーカイブ",
      Asia: "アジア",
      AtoZ: "タイトル順（昇）",
      Attachments: "添付ファイル",
      Attributes: "素材",
      Aug: "8月",
      Australia: "オーストラリア",
      Availability: "利用可否",
      Available: "利用可能",
      Back: "戻る",
      Beauty: "美容",
      Billing: "請求",
      Bitcoin: "ビットコイン",
      Blouse: "ブラウス",
      browse: "ここをクリック",
      Browse: "ブラウズ",
      Budget: "予算",
      Calendar: "カレンダー",
      Canada: "カナダ",
      Cancel: "キャンセル",
      Canceled: "キャンセル",
      Cars: "車",
      CaseStudy: "事例",
      Category: "カテゴリー",
      Chat: "チャット",
      Chats: "チャット",
      Checklist: "チェックリスト",
      City: "市町村",
      Clear: "クリア",
      Client: "クライアント",
      Compact: "コンパクト",
      Complete: "完了",
      Completed: "完了",
      Compose: "メールを作成する",
      Confirm: "確定する",
      Connect: "つながる",
      Connected: "つながり済み",
      Connections: "つながり",
      Contact: "お問い合わせ",
      Contacts: "連絡先",
      Conversions: "コンバージョン",
      Copy: "コピー",
      copyRight: "© 2021. Deloitte<1 />詳細は<3>利用規定</3>をご覧ください",
      Cost: "コスト",
      Cotton: "コットン",
      Country: "国名",
      Create: "作成",
      CreditCard: "クレジットカード",
      Customer: "カスタマー",
      Customers: "カスタマー",
      Dark: "ダーク",
      Dashboard: "ダッシュボード",
      Date: "日付",
      Day: "日",
      Deadline: "締め切り日",
      Dec: "12月",
      Delete: "削除",
      Description: "説明",
      Details: "詳細",
      Division: "部署",
      Download: "ダウンロード",
      Draft: "手形支払い",
      Dress: "ドレス",
      Edit: "編集",
      Email: "Eメールアドレス",
      Emails: "Eメールアドレス",
      Engagements: "エンゲージメント",
      Europe: "ヨーロッパ",
      Excerpt: "概要",
      Expert: "熟練者",
      Export: "エクスポートする",
      Feb: "2月",
      Feed: "フィード",
      Finance: "財務",
      Freelance: "フリーランス",
      General: "全般",
      Germany: "ドイツ",
      Import: "インポートする",
      Impressions: "インプレッション",
      InActive: "非アクティブ",
      Inbox: "受信トレイ",
      Internship: "インターンシップ",
      Inventory: "在庫",
      Invoices: "請求書",
      Jan: "1月",
      Jewelry: "ジュエリー",
      Jobs: "仕事",
      Jul: "7月",
      Jun: "6月",
      Kanban: "カンバン",
      Labels: "ラベル",
      Languages: "言語",
      Level: "レベル",
      Light: "ライト",
      Like: "スキ",
      Limited: "在庫僅少",
      List: "リスト",
      Location: "地域",
      Login: "ログイン",
      Logout: "ログアウト",
      Logs: "ログ",
      Mail: "メール",
      Management: "管理",
      Mar: "3月",
      Marketing: "マーケティング",
      May: "5月",
      Members: "メンバー",
      Message: "お問い合わせ内容",
      mo: "月",
      Month: "月",
      monthly: "毎月",
      Move: "移動",
      Name: "名前",
      News: "ニュース",
      Next: "次へ",
      Notifications: "通知",
      Nov: "11月",
      Novice: "初心者",
      Number: "No.",
      Oct: "10月",
      Orders: "注文",
      Organic: "検索エンジン",
      Organize: "商品分類",
      Outsourcing: "アウトソーシング",
      Overview: "概要",
      paid: "支払い済み",
      Paid: "支払い済み",
      Password: "パスワード",
      Past: "前職",
      PayPal: "ペイパル",
      Pending: "ペンディング",
      Phone: "電話番号",
      Phones: "電話機",
      Platforms: "プラットフォーム",
      Premium: "プレミアム",
      Previous: "前へ",
      Price: "価格",
      Prices: "価格",
      Products: "商品",
      Profile: "プロフィール",
      Profit: "利益",
      projectReviewCardLink: "| <1>低予算</1>について | {{ createedAt }}前",
      Projects: "プロジェクト",
      Prospect: "見込み",
      Purchase: "購入",
      Recommended: "おすすめ順",
      Referral: "別サイト",
      Refresh: "更新",
      Refunded: "返金済み",
      Rejected: "返品",
      Rename: "名前変更",
      Reply: "返信",
      Required: "必須項目です",
      Returning: "リピート",
      Reviews: "レビュー",
      Roles: "役割",
      Russia: "ロシア",
      Sales: "売上",
      Save: "保存",
      Search: "検索",
      Security: "セキュリティ",
      selected: "個選択",
      Send: "送信する",
      Sep: "9月",
      Services: "サービス",
      Settings: "設定",
      Share: "シェアする",
      Shippable: "出荷可能",
      Shirts: "シャツ",
      Social: "ソーシャル",
      Spent: "消費金額",
      Status: "ステータス",
      Strategy: "戦略",
      Subject: "件名",
      Submit: "送信",
      Subscribe: "申し込む",
      System: "システム",
      Tags: "タグ",
      templates: "テンプレート",
      Theme: "テーマ",
      Timeline: "タイムライン",
      Title: "件名",
      To: "宛先",
      Today: "今日",
      Total: "合計",
      Type: "採用形態",
      Unavailable: "利用不可",
      Unlike: "スキを解除",
      Unsubscribe: "解約する",
      Upload: "アップロード",
      USD: "米ドル",
      Visitors: "ユーザー数",
      Visits: "アクセス数",
      Watch: "ウォッチ",
      Week: "週",
      ZtoA: "タイトル順（降）",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
  keySeparator: ">>",
  nsSeparator: "||",
});
