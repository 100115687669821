/* eslint no-template-curly-in-string:0 */

export const localeJp = {
  mixed: {
    default: "無効な形式です",
    required: "必須項目です",
    oneOf: "${values}のいずれかを入力してください",
    notOneOf: "${values}以外のものを入力してください",
    defined: "必須項目です",
  },
  string: {
    length: "${length}文字で入力してください",
    min: "${min}文字以上入力してください",
    max: "${max}文字以下にしてください",
    matches: '次の形式で入力してください: "${regex}"',
    email: "正しいメールアドレスを入力してください",
    url: "正しいURLを入力してください",
    uuid: "正しいUUIDを入力してください",
    trim: "前後の空白を取り除いてください",
    lowercase: "小文字のみ入力してください",
    uppercase: "大文字のみ入力してください",
  },
  number: {
    min: "${min}以上にしてください",
    max: "${max}以下にしてください",
    lessThan: "${less}未満にしてください",
    moreThan: "${more}より大きい値にしてください",
    positive: "正の数にしてください",
    negative: "負の数にしてください",
    integer: "整数を入力してください",
  },
  date: {
    min: "${min}以降の日付を入力してください",
    max: "${max}以前の日付を入力してください",
  },
  boolean: {
    isValue: "${value}の値を入力してください",
  },
  object: {
    noUnknown: "登録されていないキーは入力できません: ${unknown}",
  },
  array: {
    min: "${min}つ以上入力してください",
    max: "${max}つ以下の入力にしてください",
    length: "${length}つの入力にしてください",
  },
};
