import type { FC } from "react";
import PropTypes from "prop-types";
import {
  Avatar,
  Divider,
  Button,
  Box,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Skeleton,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { parseISO, formatDistanceToNow } from "date-fns";
import ja from "date-fns/locale/ja";
import type { Notification } from "../../contenfulGraphql";
import { ApolloError } from "@apollo/client";
import { useTranslation } from "react-i18next";

interface AnnouncementsProps {
  loading: boolean;
  error?: Error;
  data?: Notification[];
  handleLinkClick: () => void;
  handleButtonClick: () => void;
}

const BoxText: FC<{ text: string }> = ({ text }) => (
  <Box sx={{ p: 2 }}>
    <Typography color="textPrimary" variant="subtitle2">
      {text}
    </Typography>
  </Box>
);

const Announcements: FC<AnnouncementsProps> = (props) => {
  const { loading, error, data, handleLinkClick, handleButtonClick } = props;
  const { t, i18n } = useTranslation();

  if (loading)
    return (
      <>
        <Box sx={{ mt: 1, mr: 1, display: "flex", justifyContent: "flex-end" }}>
          <Button color="inherit" size="small" variant="text">
            <Skeleton animation="wave" />
          </Button>
        </Box>
        <List disablePadding>
          {[...Array(2)].map((_, k) => (
            <ListItem key={k.toString()}>
              <ListItemAvatar>
                <Skeleton variant="rectangular" width={40} height={40} />
              </ListItemAvatar>
              <ListItemText>
                <Skeleton animation="wave" />
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </>
    );

  if (error) return <BoxText text="Error" />;

  return (
    <>
      {data.length === 0 ? (
        <BoxText text={t("There are no notifications")} />
      ) : (
        <>
          <Box
            sx={{ mt: 1, mr: 1, display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              onClick={handleButtonClick}
              color="inherit"
              size="small"
              variant="text"
            >
              {t("Mark all as read")}
            </Button>
          </Box>
          <Box sx={{ maxHeight: 320, overflow: "auto" }}>
            <List disablePadding>
              {data.map((notification) => (
                <ListItem key={notification.sys.id}>
                  <ListItemAvatar>
                    <Avatar src={notification.thumbnail.url} variant="square" />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Link
                        color="textPrimary"
                        sx={{ cursor: "pointer" }}
                        underline="none"
                        variant="subtitle2"
                        component={RouterLink}
                        to={`/dashboard/notifications/${notification.sys.id}`}
                        onClick={handleLinkClick}
                      >
                        {notification.title}
                      </Link>
                    }
                    secondary={
                      i18n.language === "ja"
                        ? formatDistanceToNow(
                            parseISO(notification.sys.firstPublishedAt),
                            {
                              includeSeconds: true,
                              addSuffix: true,
                              locale: ja,
                            }
                          )
                        : formatDistanceToNow(
                            parseISO(notification.sys.firstPublishedAt),
                            {
                              includeSeconds: true,
                              addSuffix: true,
                            }
                          )
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </>
      )}
      <Divider />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          p: 1,
        }}
      >
        <RouterLink
          to="/dashboard/notifications"
          style={{ textDecoration: "none" }}
        >
          <Button
            color="primary"
            size="small"
            variant="text"
            onClick={handleLinkClick}
          >
            {t("View all announcements")}
          </Button>
        </RouterLink>
      </Box>
    </>
  );
};

BoxText.propTypes = {
  text: PropTypes.string.isRequired,
};

Announcements.propTypes = {
  handleLinkClick: PropTypes.func.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.instanceOf(ApolloError),
  data: PropTypes.any,
};

export default Announcements;
