import type { FC } from 'react';
import type { Theme } from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';
import type { SxProps } from '@material-ui/system';

interface LogoProps {
  sx?: SxProps<Theme>;
}

const LogoRoot = experimentalStyled('svg')``;

const Logo: FC<LogoProps> = (props) => (
  <LogoRoot
    height="52"
    version="1.1"
    viewBox="0 0 52 52"
    width="52"
    {...props}
  >
    <title>dXCo Cloud Platform</title>
    <g>
      <circle cx="26" cy="26" r="20" fill="#86bc25" />
    </g>
  </LogoRoot>
);

export default Logo;
