import { createContext, useEffect, useReducer } from "react";
import type { FC, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Amplify, { Auth } from "aws-amplify";
import amplifyConfig from "../aws-exports";
import type { User } from "../types/user";

Amplify.configure({
  ...amplifyConfig,
  ...{
    aws_appsync_graphqlEndpoint: `https://${process.env.REACT_APP_APPSYNC_APIID}.appsync-api.${process.env.REACT_APP_APPSYNC_REGION}.amazonaws.com/graphql`,
    aws_appsync_region: process.env.REACT_APP_APPSYNC_REGION,
    aws_appsync_authenticationType:
      process.env.REACT_APP_APPSYNC_AUTHENTICATIONTYPE,
  },
});

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

interface AuthContextValue extends State {
  platform: "Amplify";
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  register: (email: string, password: string) => Promise<void>;
  verifyCode: (username: string, code: string) => Promise<void>;
  resendCode: (username: string) => Promise<void>;
  passwordForceChange: (
    email: string,
    password: string,
    newString: string
  ) => Promise<void>;
  passwordRecovery: (username: string) => Promise<void>;
  passwordReset: (
    username: string,
    code: string,
    newPassword: string
  ) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: "INITIALIZE";
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: "LOGIN";
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: "LOGOUT";
};

type RegisterAction = {
  type: "REGISTER";
};

type VerifyCodeAction = {
  type: "VERIFY_CODE";
};

type ResendCodeAction = {
  type: "RESEND_CODE";
};

type PasswordForceChangeAction = {
  type: "PASSWORD_FORCE_CHANGE";
};

type PasswordRecoveryAction = {
  type: "PASSWORD_RECOVERY";
};

type PasswordResetAction = {
  type: "PASSWORD_RESET";
};

type Action =
  | InitializeAction
  | LoginAction
  | LogoutAction
  | RegisterAction
  | VerifyCodeAction
  | ResendCodeAction
  | PasswordForceChangeAction
  | PasswordRecoveryAction
  | PasswordResetAction;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state: State): State => ({ ...state }),
  VERIFY_CODE: (state: State): State => ({ ...state }),
  RESEND_CODE: (state: State): State => ({ ...state }),
  PASSWORD_FORCE_CHANGE: (state: State): State => ({ ...state }),
  PASSWORD_RECOVERY: (state: State): State => ({ ...state }),
  PASSWORD_RESET: (state: State): State => ({ ...state }),
};

const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: "Amplify",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  verifyCode: () => Promise.resolve(),
  resendCode: () => Promise.resolve(),
  passwordForceChange: () => Promise.resolve(),
  passwordRecovery: () => Promise.resolve(),
  passwordReset: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const user = await Auth.currentAuthenticatedUser();

        // Here you should extract the complete user profile to make it
        // available in your entire app.
        // The auth state only provides basic information.

        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: true,
            user: {
              id: user.attributes.sub,
              avatar: user.attributes.picture,
              email: user.attributes.email,
              name: user.attributes.name,
              plan: "Premium",
            },
          },
        });
      } catch (error) {
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string): Promise<void> => {
    const user = await Auth.signIn(email, password);

    if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
      navigate("/authentication/password-force-change", {
        state: {
          email,
          password,
        },
      });
      return;
    }

    if (user.challengeName) {
      console.error(
        `Unable to login, because challenge "${user.challengeName}" is mandated and we did not handle this case.`
      );
      return;
    }

    dispatch({
      type: "LOGIN",
      payload: {
        user: {
          id: user.attributes.sub,
          avatar: user.attributes.picture,
          email: user.attributes.email,
          name: user.attributes.name,
          plan: "Premium",
        },
      },
    });
  };

  const logout = async (): Promise<void> => {
    await Auth.signOut();
    dispatch({
      type: "LOGOUT",
    });
  };

  const register = async (email: string, password: string): Promise<void> => {
    await Auth.signUp({
      username: email,
      password,
      attributes: { email },
    });
    dispatch({
      type: "REGISTER",
    });
  };

  const verifyCode = async (username: string, code: string): Promise<void> => {
    await Auth.confirmSignUp(username, code);
    dispatch({
      type: "VERIFY_CODE",
    });
  };

  const resendCode = async (username: string): Promise<void> => {
    await Auth.resendSignUp(username);
    dispatch({
      type: "RESEND_CODE",
    });
  };

  const passwordForceChange = async (
    email: string,
    password: string,
    newPassword: string
  ): Promise<void> => {
    const user = await Auth.signIn(email, password);
    const { requiredAttributes } = user.challengeParam;
    await Auth.completeNewPassword(user, newPassword, requiredAttributes);
    dispatch({
      type: "PASSWORD_FORCE_CHANGE",
    });
  };

  const passwordRecovery = async (username: string): Promise<void> => {
    await Auth.forgotPassword(username);
    dispatch({
      type: "PASSWORD_RECOVERY",
    });
  };

  const passwordReset = async (
    username: string,
    code: string,
    newPassword: string
  ): Promise<void> => {
    await Auth.forgotPasswordSubmit(username, code, newPassword);
    dispatch({
      type: "PASSWORD_RESET",
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: "Amplify",
        login,
        logout,
        register,
        verifyCode,
        resendCode,
        passwordForceChange,
        passwordRecovery,
        passwordReset,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
