/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createContract = /* GraphQL */ `
  mutation CreateContract(
    $input: CreateContractInput!
    $condition: ModelContractConditionInput
  ) {
    createContract(input: $input, condition: $condition) {
      id
      email
      plan
      serviceId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateContract = /* GraphQL */ `
  mutation UpdateContract(
    $input: UpdateContractInput!
    $condition: ModelContractConditionInput
  ) {
    updateContract(input: $input, condition: $condition) {
      id
      email
      plan
      serviceId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteContract = /* GraphQL */ `
  mutation DeleteContract(
    $input: DeleteContractInput!
    $condition: ModelContractConditionInput
  ) {
    deleteContract(input: $input, condition: $condition) {
      id
      email
      plan
      serviceId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createRead = /* GraphQL */ `
  mutation CreateRead(
    $input: CreateReadInput!
    $condition: ModelReadConditionInput
  ) {
    createRead(input: $input, condition: $condition) {
      id
      notificationId
      read
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateRead = /* GraphQL */ `
  mutation UpdateRead(
    $input: UpdateReadInput!
    $condition: ModelReadConditionInput
  ) {
    updateRead(input: $input, condition: $condition) {
      id
      notificationId
      read
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteRead = /* GraphQL */ `
  mutation DeleteRead(
    $input: DeleteReadInput!
    $condition: ModelReadConditionInput
  ) {
    deleteRead(input: $input, condition: $condition) {
      id
      notificationId
      read
      createdAt
      updatedAt
      owner
    }
  }
`;
