import { useRef } from "react";
import { usePreviousValue } from "./usePreviousValue";

export const useDeepMemo = <T = unknown>(
  deps: T,
  compare: (prev: T, current: T) => boolean
): T => {
  const ref = useRef(deps);
  const prev = usePreviousValue(deps);
  if (prev === undefined) {
    return ref.current;
  }
  if (!compare(prev, deps)) {
    ref.current = deps;
  }
  return ref.current;
};
