/* eslint-disable react/no-array-index-key */
import type { FC } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import { useTranslation } from "react-i18next";

const ContactSupport: FC = () => {
  const handleContactSupport = (): void => {
    window.open("https://www.avocadot.es/", "_blank");
  };
  const { t } = useTranslation();

  return (
    <>
      <Tooltip title={t("Contact Support")}>
        <IconButton color="inherit" onClick={handleContactSupport}>
          <ContactSupportIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default ContactSupport;
