import {
  ApolloClient,
  InMemoryCache,
  ApolloQueryResult,
  gql,
} from "@apollo/client";
import { print } from "graphql";
import { Query } from "src/contenfulGraphql";
import { API } from "aws-amplify";
import amplifyConfig from "../aws-exports";

export const client = new ApolloClient({
  uri: `https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE}/environments/${process.env.REACT_APP_CONTENTFUL_ENVIRONMENT}`,
  headers: {
    authorization: `Bearer ${process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN}`,
  },
  cache: new InMemoryCache(),
});

export const API_NAME = amplifyConfig.aws_cloud_logic_custom[0].name;

export const CONTENTFUL_API_PATH = `/content/v1/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE}/environments/${process.env.REACT_APP_CONTENTFUL_ENVIRONMENT}`;

export const callContentfulApi = API.post.bind(
  API,
  API_NAME,
  CONTENTFUL_API_PATH
);

export const LIST_SERVICES = gql`
  query ($locale: String) {
    serviceCollection(locale: $locale) {
      items {
        sys {
          id
        }
        title
        thumbnail {
          url
        }
        summary
        category
        contactPerson
        contactEmail
        description
        priority
        previewUrl
        tags
        caseStudiesCollection(limit: 10) {
          items {
            sys {
              id
            }
            title
            client
            tags
            thumbnail {
              url
            }
            excerpt
            contents
            brochure {
              url
            }
          }
        }
      }
    }
  }
`;

export const QUERY_SERVICE_BY_ID = gql`
  query ($id: String, $locale: String) {
    serviceCollection(where: { sys: { id: $id } }, locale: $locale) {
      items {
        sys {
          id
        }
        title
        thumbnail {
          url
        }
        summary
        category
        contactPerson
        contactEmail
        description
        priority
        previewUrl
        tags
        caseStudiesCollection(limit: 10) {
          items {
            sys {
              id
            }
            title
            client
            tags
            thumbnail {
              url
            }
            excerpt
            contents
            brochure {
              url
            }
          }
        }
      }
    }
  }
`;

export const parseServiceCollection = (result: ApolloQueryResult<Query>) =>
  result.data.serviceCollection.items.map((service) => ({
    id: service.sys.id,
    caseStudies: service.caseStudiesCollection.items.map((caseStudy) => ({
      id: caseStudy.sys.id,
      contents: caseStudy.contents ?? "",
      brochure: caseStudy.brochure ? caseStudy.brochure.url : "",
      client: caseStudy.client ?? "",
      excerpt: caseStudy.excerpt ?? "",
      tags: caseStudy.tags ?? [],
      thumbnail: caseStudy.thumbnail ? caseStudy.thumbnail.url : "",
      title: caseStudy.title ?? "",
    })),
    category: service.category ?? "",
    contactPerson: { name: service.contactPerson, email: service.contactEmail },
    description: service.description ?? "",
    thumbnail: service.thumbnail ? service.thumbnail.url : "",
    previewUrl: service.previewUrl ?? "",
    priority: service.priority ?? 999,
    summary: service.summary ?? "",
    tags: service.tags ?? [],
    title: service.title ?? "",
  }));

export const queryServiceById = async (id: string, locale: string) => {
  const result = (await client.query({
    query: QUERY_SERVICE_BY_ID,
    variables: { id, locale },
  })) as ApolloQueryResult<Query>;
  return parseServiceCollection(result).length === 1
    ? parseServiceCollection(result)[0]
    : null;
};

export const queryListService = async (locale: string) => {
  const result = (await callContentfulApi({
    body: {
      query: print(LIST_SERVICES),
      variables: { locale },
    },
  })) as ApolloQueryResult<Query>;
  return parseServiceCollection(result);
};

export const LIST_LATEST_NOTIFICATIONS = gql`
  query ListLatestNotifications($date: DateTime, $locale: String) {
    notificationCollection(
      order: sys_firstPublishedAt_DESC
      where: { OR: [{ expireDate_exists: false }, { expireDate_gte: $date }] }
      locale: $locale
    ) {
      total
      items {
        sys {
          id
          firstPublishedAt
        }
        title
        thumbnail {
          url
        }
      }
    }
  }
`;

export const GET_NOTIFICATIONS = gql`
  query GetNotifications(
    $id: String
    $limit: Int
    $skip: Int
    $query: [NotificationFilter]
    $locale: String
  ) {
    notificationCollection(
      order: sys_firstPublishedAt_DESC
      limit: $limit
      skip: $skip
      where: { sys: { id: $id }, OR: $query }
      locale: $locale
    ) {
      total
      skip
      limit
      items {
        sys {
          id
          firstPublishedAt
        }
        title
        tags
        excerpt
        contents
        thumbnail {
          sys {
            id
          }
          url
        }
        expireDate
      }
    }
  }
`;

export const queryNotificationById = async (id: string, locale: string) => {
  const result = (await callContentfulApi({
    body: {
      query: print(GET_NOTIFICATIONS),
      variables: { id, locale },
    },
  })) as ApolloQueryResult<Query>;
  return result.data.notificationCollection.items.length === 1
    ? {
        id: result.data.notificationCollection.items[0].sys.id,
        firstPublishedAt:
          result.data.notificationCollection.items[0].sys.firstPublishedAt,
        thumbnail: result.data.notificationCollection.items[0].thumbnail.url,
        title: result.data.notificationCollection.items[0].title,
        contents: result.data.notificationCollection.items[0].contents,
        tags: result.data.notificationCollection.items[0].tags,
      }
    : Promise.reject(new Error("cannot get notification"));
};
