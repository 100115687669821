import { useRef, useState, useEffect } from "react";
import type { FC } from "react";
import {
  Badge,
  Box,
  Button,
  IconButton,
  Popover,
  Tooltip,
  Typography,
  Divider,
  makeStyles,
} from "@material-ui/core";
import BellIcon from "../../icons/Bell";
import Announcements from "./NotificationsPopoverAnnouncements";
import { LIST_LATEST_NOTIFICATIONS } from "../../utils/contentful";
import { useNotification } from "../../hooks/useNotification";
import { formatISO } from "date-fns";
import { onCreateRead } from "../../graphql/dcpdashboardbackend/subscriptions";
import useAuth from "../../hooks/useAuth";
import { API, graphqlOperation } from "aws-amplify";
import { createReadRecord } from "../../utils/notificationRead";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  root: {
    borderRadius: 0,
    paddingLeft: 0,
    paddingRight: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
});

const tabs = [
  { label: "Announcement", value: "announcement" },
  { label: "News", value: "news" },
];

const today = formatISO(new Date());

const NotificationsPopover: FC = () => {
  const { user } = useAuth();
  const classes = useStyles();
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<string>(tabs[0].value);
  const { t, i18n } = useTranslation();

  const { isLoading, error, data, addRead } = useNotification(
    LIST_LATEST_NOTIFICATIONS,
    { date: today, locale: i18n.language }
  );

  useEffect(() => {
    if (open) setCurrentTab(tabs[0].value);
  }, [open]);

  useEffect(() => {
    const subscription = API.graphql(
      graphqlOperation(onCreateRead, { owner: user.id })
      // Only the Observable has the subscribe.
      // Already GitHub issue exists.
      // @ts-ignore
    ).subscribe({
      next: ({ value }) => {
        addRead({
          [value.data.onCreateRead.notificationId]: {
            id: value.data.onCreateRead.id,
            read: true,
          },
        });
      },
      error: (e) => console.warn(e),
    });

    return () => subscription.unsubscribe();
  }, []);

  const handleTabsChange = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    setCurrentTab(e.currentTarget.value);
  };

  const handleOpen = async (): Promise<void> => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleButtonClick = () => {
    const notificationIds = data.notification.notificationCollection.items
      .filter((item) => !data.read[item.sys.id])
      .map((i) => i.sys.id);
    Promise.all(
      notificationIds.map(async (notificationId) =>
        createReadRecord(notificationId)
      )
    ).catch((e) => console.error(e));
  };

  return (
    <>
      <Tooltip title={t("Notifications")}>
        <IconButton color="inherit" ref={anchorRef} onClick={handleOpen}>
          <Badge
            color="error"
            badgeContent={
              !error && data
                ? data.notification.notificationCollection.items.filter(
                    (item) => !data.read[item.sys.id]
                  ).length
                : 0
            }
          >
            <BellIcon fontSize="small" />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 320 },
        }}
      >
        <Box sx={{ ml: 2, mt: 2, mr: 2 }}>
          <Typography color="textPrimary" variant="h6">
            {t("Notifications")}
          </Typography>
        </Box>
        <Box display="flex" sx={{ ml: 2 }}>
          {tabs.map((tab) => (
            <Box
              key={tab.value}
              sx={
                currentTab === tab.value
                  ? {
                      borderBottom: 1,
                      borderColor: "primary.main",
                    }
                  : null
              }
            >
              <Button
                className={classes.root}
                disableRipple
                onClick={handleTabsChange}
                value={tab.value}
                color={currentTab === tab.value ? "primary" : "inherit"}
              >
                {t(tab.label)}
              </Button>
            </Box>
          ))}
        </Box>
        <Divider />
        {currentTab === "announcement" && (
          <Announcements
            handleLinkClick={handleClose}
            handleButtonClick={handleButtonClick}
            loading={isLoading}
            error={error}
            data={
              !error && data
                ? data.notification.notificationCollection.items.filter(
                    (item) => !data.read[item.sys.id]
                  )
                : null
            }
          />
        )}
        {currentTab === "news" && (
          <Box sx={{ p: 2 }}>
            <Typography color="textPrimary" variant="subtitle2">
              {t("There are no news")}
            </Typography>
          </Box>
        )}
      </Popover>
    </>
  );
};

export default NotificationsPopover;
