/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getContract = /* GraphQL */ `
  query GetContract($id: ID!) {
    getContract(id: $id) {
      id
      email
      plan
      serviceId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listContracts = /* GraphQL */ `
  query ListContracts(
    $filter: ModelContractFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContracts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        plan
        serviceId
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getRead = /* GraphQL */ `
  query GetRead($id: ID!) {
    getRead(id: $id) {
      id
      notificationId
      read
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listReads = /* GraphQL */ `
  query ListReads(
    $filter: ModelReadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        notificationId
        read
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
