/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateContract = /* GraphQL */ `
  subscription OnCreateContract($owner: String!) {
    onCreateContract(owner: $owner) {
      id
      email
      plan
      serviceId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateContract = /* GraphQL */ `
  subscription OnUpdateContract($owner: String!) {
    onUpdateContract(owner: $owner) {
      id
      email
      plan
      serviceId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteContract = /* GraphQL */ `
  subscription OnDeleteContract($owner: String!) {
    onDeleteContract(owner: $owner) {
      id
      email
      plan
      serviceId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateRead = /* GraphQL */ `
  subscription OnCreateRead($owner: String!) {
    onCreateRead(owner: $owner) {
      id
      notificationId
      read
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateRead = /* GraphQL */ `
  subscription OnUpdateRead($owner: String!) {
    onUpdateRead(owner: $owner) {
      id
      notificationId
      read
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteRead = /* GraphQL */ `
  subscription OnDeleteRead($owner: String!) {
    onDeleteRead(owner: $owner) {
      id
      notificationId
      read
      createdAt
      updatedAt
      owner
    }
  }
`;
